.slogan {
  font-family: "Permanent Marker", cursive;
  font-size: 1.5em;
  position: relative;
  left: 15%;
  transform: rotate(-10deg) skew(-10deg);
  background-image: linear-gradient(red, red);
  background-size: 100% 10px;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  transition: background-size 0.7s, background-position 0.5s ease-in-out;
}

.sloganSection {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
